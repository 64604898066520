import { render, staticRenderFns } from "./SekkeiGyoumo.vue?vue&type=template&id=afb41702&scoped=true"
import script from "./SekkeiGyoumo.vue?vue&type=script&lang=js"
export * from "./SekkeiGyoumo.vue?vue&type=script&lang=js"
import style0 from "./SekkeiGyoumo.vue?vue&type=style&index=0&id=afb41702&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afb41702",
  null
  
)

export default component.exports